export default {
  SET_TOKEN: "SET_TOKEN",
  SET_EXPIRED: "SET_EXPIRED",

  CALL_API: "CALL_API",

  SHOW_LOADING: "SHOW_LOADING",
  HIDE_LOADING: "HIDE_LOADING",
  RESET_LOADING: "RESET_LOADING",

  SHOW_NOTIFICATION: "SHOW_NOTIFICATION",

  LOGIN: "LOGIN_START",
  LOGOUT: "LOGOUT",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILED: "LOGIN_FAILED"
}
